import { Component } from "react";
import { withRouter } from "react-router-dom";
import rdProgramSpanish from "../../styles/img/RDProgram/RDProgram_spanish_horizontal.png";
import "../../styles/RDProgramPage.css";
class RDProgramPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount = () => {
  }

  render() {
    const imageDatabase = {
      "rd-program-spanish": rdProgramSpanish
    }

    return (
      <div className="rdprogram-main">
        <img
          className="program-img"
          src={imageDatabase["rd-program-spanish"]} 
          alt="RD Program"
        />
      </div>
    );
  }
}

export default withRouter(RDProgramPage);